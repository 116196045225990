@import '@/styles/base-imports';

.container {
    position: relative;
    width: 100vw;
    height: 100dvh;
    max-height: 33svh;
    overflow: hidden;

    .hero {
        height: 100%;
    }

    .overlayContainer {
        position: absolute;
        inset: 0;
        display: grid;
        place-content: center;
        height: 100%;
        color: #fff;

        * {
            color: inherit;
            z-index: 10;
            position: relative;
        }

        h1 {
            position: relative;
            z-index: 10;
            margin-bottom: .5rem;

            .onn {
                position: relative;
            }
        }

        h2 {
            margin-top: .5rem;
        }

        h1,
        h2 {
            text-align: center;
        }

        &.small {
            h1 {
                font-size: 1.8rem;

                @include desktop {
                    font-size: 2rem;
                }
            }

            h2 {
                font-size: 1.3rem;

                @include desktop {
                    font-size: 1.5rem;
                }
            }
        }

        &.medium {
            h1 {
                font-size: 2rem;

                @include desktop {
                    font-size: 2.5rem;
                }
            }

            h2 {
                font-size: 1.5rem;

                @include desktop {
                    font-size: 2rem;
                }
            }
        }

        &.large {
            h1 {
                font-size: 2.4rem;

                @include desktop {
                    font-size: 3.5rem;
                }
            }

            h2 {
                font-size: 1.8rem;

                @include desktop {
                    font-size: 2.5rem;
                }
            }
        }
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: var(--overlay-color)
    }

}