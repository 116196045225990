$secondary: #b5883f;
$secondary-light: #f4f0e8;
$secondary-light-text: rgb(110, 112, 112);

$headline: #707070;

// bulma overrides
$primary: #274f66;
$success: #89c647;
$danger: #e05c38;
$info: #1191cd;
$link: $headline;

$family-plex-sans: var(--font-ibm-plex-sans);
$family-plex-serif: var(--font-ibm-plex-serif);
$family-barlow: var(--font-barlow);
$family-barlow-condensed: var(--font-barlow-semi-condensed);
$family-lato: var(--font-lato);

$family-primary: $family-plex-sans;
$family-secondary: $family-barlow-condensed;

$family-headline: $family-barlow-condensed;
$family-subheadline: $family-plex-serif;
$family-body: $family-lato;

$body-line-height: 1.3;
$text: #707070;
$text-muted: #acabab;
$offwhite: #fcfcfc;

$size-small: 0.85rem;
// end bulma overrides

$fixed-nav-size: 4.75rem;
$global-content-gap: 4rem;

$onn-blues: #429ad5, #5ba8d3, #7db8d1, #98c9d3;