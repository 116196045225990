@import '@/styles/base-imports';

.container {
  position: relative;
  transform-origin: 50% 50%;

  &.overlay {
    display: grid;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.85);
      z-index: 1;
    }
  }

  .overlayText {
    z-index: 2;
    color: $primary;
    font-size: 2rem;
    font-weight: 700;
    padding: 1rem;
    display: grid;
    min-height: 100%;

    &.center {
      align-items: center;
    }

    &>* {
      margin: 0;
      padding: 0;
    }

    @include tablet {
      padding: 2rem;
      justify-content: start;
    }
  }

  .figureParent {
    position: relative;
    height: 100%;

    &.animate {
      overflow: hidden;

      img {
        transition: scale .25s linear;

        @media (prefers-reduced-motion) {
          transition: none;
        }
      }

      &:hover {
        img {
          scale: 1.15;

          @media (prefers-reduced-motion) {
            scale: 1;
          }
        }
      }
    }
  }

  img {
    object-fit: cover;

    &.greyscale {
      filter: grayscale(100%);
      opacity: .5;
    }
  }

  figcaption {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    padding-inline: 1rem;
    padding-block: 1rem;
    font-size: 1.05rem;
  }
}